
































































import Vue from 'vue'
// import PhoneIcon from '@/assets/icons/phone.svg'
// import EmailIcon from '@/assets/icons/email.svg'
import EmailLoginIcon from '@/assets/icons/email-login.svg'
import UserLockIcon from '@/assets/icons/user-lock.svg'
// import VTab from '@/components/VTab.vue'
export default Vue.extend({
  components:{
    // PhoneIcon,
    // EmailIcon,
    EmailLoginIcon,
    UserLockIcon,
    // VTab
  },

  data(){
    return {
      loading: false,
      credential:{},
      errors:{}
    }
  },

  methods: {
    onSubmit(e: any){
      // 
    },

    loginEmail(){
      // 
      
      this.loading = true;
      this.$store.dispatch('auth/loginByEmail', this.credential)
      .then(response => {
        let path: any = '/';
        console.log('OK!!!');
        
          if (this.$route.query['redirect']) {
            path = this.$route.query['redirect']
          }
          
          this.$router.push({path: path})
      })
      .catch(error => {
        console.log('error ', error);
        if (error.data.message) {
          console.log('message ', error.data.message);
          if (error.data.message === 'user_not_found') {
            
            //@ts-ignore
            this.$refs.form.setErrors({
              email: ['Identifiant incorrect. Veuillez rééssayer.']
            })
          }
          else if (error.data.message === 'invalid_password') {
            //@ts-ignore
            this.$refs.form.setErrors({
              password: ['Mot de passe incorrect. Veuillez rééssayer.']
            })
          } 
        }
      })
      .finally(() => {this.loading =false})
    },

    loginByTelephone(){
      // 
    }
  }
})
